import React, { useId, useState } from "react";
import "./App.css";
import styled from "styled-components";
import Main from "./components/Main";
import giftCardData, { GiftCard } from "./components/cardData";
import CartModal from "./components/CartModal";
import { PackageTests, PackageT } from "./data";
import SuccessModal from "./components/SuccessModal";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { v4 as uuid } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import dotenv from "dotenv";
import Headers from "./components/Header2";

interface ShowModal {
  show: boolean;
}
const Container = styled.div<ShowModal>`
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  width: 100%;
`;
export interface Cart {
  price: number;
  title: string;
  listOfTest?: string[];
  quantity?: number;
  totalPrice?: number;
  id: number;
  category: string;
}

function App() {
  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
  const [dataSource, setDataSource] = React.useState<PackageT[]>(PackageTests);
  const [packages, setPackages] = React.useState<PackageT[]>(PackageTests);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [filterParameter, setFilterParameter] =
    React.useState<string>("All Packages");
  const [active, setActive] = React.useState<boolean>(true);
  const [cart, setCart] = React.useState<Cart[]>([]);
  const [totalPrice, setTotalPrice] = React.useState<number>(0);
  const [itemOffset, setItemOffset] = React.useState<number>(0);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [giftPackages, setGiftCardData] =
    React.useState<GiftCard[]>(giftCardData);

  React.useEffect(() => {
    if (cart.length !== 0) {
      handleSetPrice();
    }
  }, [cart]);

  React.useEffect(() => {
    setFilterParameter("All Packages");
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    const filterId = urlParams.get("product");
    if (filterId) {
      setFilterParameter(() => {
        if (filterId) {
          return filterId;
        } else return "";
      });
      const filter = dataSource.filter(
        (item) => item.packageCategory === filterId
      );
      setPackages(filter);
      if (active !== true) {
        setActive(true);
      }
    }
  }, []);

  // get all MappedID

  const handleFilter = (parameter: string): void => {
    setFilterParameter(parameter);
    const filter = dataSource.filter(
      (item) => item.packageCategory === parameter
    );
    setPackages(filter);
    if (active !== true) {
      setActive(true);
    }
    setItemOffset(0);
  };
  const handleFilterForAllTag = () => {
    setFilterParameter("All Packages");

    setPackages(dataSource);
    if (active !== true) {
      setActive(true);
    }
    window.history.pushState({}, document.title, "/");

    setItemOffset(0);
  };
  const handleFilterForGift = (filter: string) => {
    setFilterParameter("Gift Cards");
    setActive(false);
    setItemOffset(0);
    window.history.pushState({}, document.title, "/");
  };
  const handleShowModal = (): void => {
    setShowModal(!showModal);
  };
  const handleSetPrice = (): void => {
    let data = cart.map((item) =>
      item.totalPrice !== undefined ? item.totalPrice : 0
    );
    let totalPrice: number = 0;
    for (let i = 0; i < data.length; i++) {
      totalPrice += data[i];
    }
    const price = Math.ceil(totalPrice);
    setTotalPrice(price);
  };

  const addToCart = (item: Cart, category: string) => {
    item.quantity = 1;
    item.totalPrice = item.price;
    item.id = item.id;
    if (filterParameter === "Gift Cards") {
      const newGiftData = [...giftCardData];
      const uniqueData = newGiftData.find((i) => i.id == item.id);

      if (uniqueData) {
        uniqueData.selected = false;
        setGiftCardData(newGiftData);
        const filter = dataSource.filter(
          (item) => item.packageCategory === "Gift Cards"
        );
        setPackages(filter);
      }
    } else {
      const newPackage = [...dataSource];
      const modifiedPackages = newPackage.find((i) => i.id === item.id);
      if (modifiedPackages) {
        modifiedPackages.selected = false;
        setDataSource(newPackage);
        const filter = dataSource.filter(
          (item) => item.packageCategory === category
        );
        if (filterParameter !== "All Packages") {
          setPackages(filter);
        }
      }
    }
    toast.success(`${item.title} is added to cart`, {
      autoClose: 3000,
      position: "top-center",
    });
    setCart((previousState) => [...previousState, item]);
  };
  const increaseQuantity = (item: Cart) => {
    item.quantity = item.quantity !== undefined ? item.quantity + 1 : 0;
    item.totalPrice = Math.ceil(
      item.price * (item.quantity ? item.quantity : 1)
    );
    setCart((previous) => [...previous]);
  };
  const decreaseQuantity = (item: Cart) => {
    item.quantity =
      item.quantity !== undefined && item.quantity > 0 ? item.quantity - 1 : 1;
    item.totalPrice = Math.ceil(
      item.price * (item.quantity ? item.quantity : 1)
    );
    setCart((previous) => [...previous]);
  };
  const removeFromCart = (id: number, title: string, category: string) => {
    let initData = cart.filter((item) => item.id !== id);

    setCart(initData);
    if (filterParameter === "Gift Cards") {
      const newGiftData = [...giftCardData];
      const uniqueData = newGiftData.find((i) => i.id == id);
      if (uniqueData) {
        uniqueData.selected = true;
        setGiftCardData(newGiftData);
        const filter = dataSource.filter(
          (item) => item.packageCategory === "Gift Cards"
        );
        setPackages(filter);
      }
    } else {
      const newPackage = [...dataSource];
      const modifiedPackages = newPackage.find((i) => i.id === id);
      if (modifiedPackages) {
        modifiedPackages.selected = true;
        setDataSource(newPackage);
        const filter = dataSource.filter(
          (item) => item.packageCategory === category
        );
        setPackages(filter);
      }
    }
    toast.success(`${title} is removed from cart`, {
      autoClose: 3000,
    });
  };
  return (
    <React.Fragment>
      <Container show={showModal}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {/* Same as */}
        {/* 
      <Header
        handleShowModal={handleShowModal}
        numberOfCartItem={cart.length}
      />
      */}
        <Headers
          handleShowModal={handleShowModal}
          numberOfCartItem={cart.length}
        />

        <Main
          handleFilterForGift={handleFilterForGift}
          giftData={giftPackages}
          data={packages}
          handleFilter={handleFilter}
          handleFilterForAllTag={handleFilterForAllTag}
          filterParameter={filterParameter}
          active={active}
          addToCart={addToCart}
          cartData={cart}
          itemOffSet={itemOffset}
          setItemOffset={setItemOffset}
        />
        <Footer />

        {publicKey && (
          <CartModal
            show={showModal}
            handleShowModal={handleShowModal}
            cartData={cart}
            removeFromCart={removeFromCart}
            increaseQuantity={increaseQuantity}
            decreaseQuantity={decreaseQuantity}
            totalPrice={totalPrice}
            setCart={setCart}
            setShowModal={setShowModal}
            publicKey={publicKey}
            setShowSuccess={setShowSuccess}
          />
        )}
        {showSuccess && (
          <SuccessModal show={showSuccess} setShowSuccess={setShowSuccess} />
        )}
      </Container>
    </React.Fragment>
  );
}

export default App;
