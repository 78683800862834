import React from "react";
import styled from "styled-components";
import logo from "../logoBio.png";
import { MdOutlineShoppingCart } from "react-icons/md";
import { FaBars, FaTimes } from "react-icons/fa";

const Container = styled.div`
  background-color: #fffefe;
  display: inline-block;
  z-index: 1;
  position: fixed;

  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  @media (max-width: 900px) {
    z-index: 1;
  }
`;
const BarsContainer = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: block;
    color: #212020;
    font-size: 1.5rem;
    cursor: pointer;
  }
`;
const Navbar = styled.div`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  padding: 15px 15px;
  align-items: center;
  @media (max-width: 1160px) {
    justify-content: space-between;
    padding: 15px 30px;
  }
  @media (max-width: 900px) {
    z-index: 1;
    justify-content: space-between;
    padding: 15px 30px;
    overflow-y: none;
  }
`;

const NotificationContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;
const LogoContainer = styled.a`
  height: 100%;
  display: inline-block;
  position: relative;
  text-decoration: none;
  cursor: pointer;
`;
const NavContainer = styled.div<ShowNav>`
  height: 100%;
  display: flex;
  overflow-y: none;
  align-items: center;
  transition: 1s ease-in;
  @media (max-width: 900px) {
    flex-direction: column;
    position: absolute;
    left: 0;
    top: ${(props) => (props.show ? "-100vh" : "100%")};

    background-color: #eeeaea;
    width: 100%;
    height: 70vh;
    align-items: start;
    padding-top: 20px;
  }
`;
const NavLink = styled.a`
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #1d1a1a;

  &:hover {
    background-color: #ddd;
  }
  @media (max-width: 900px) {
    height: 50px;
    width: 100%;
    text-align: left;
    padding-left: 30px;
    &:hover {
      background-color: #ddd;
    }
  }
`;
const Logo = styled.img``;
const Badge = styled.div`
  position: absolute;
  top: 3px;
  right: -10px;
  padding: 2px 7px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 15px;
  justify-content: center;
  align-items: center;
`;
const StyledButton = styled.a`
  background-color: #ed4e4e;
  max-width: 200px;
  outline: none;
  color: white;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  font-size: 12px;
  text-decoration: none;
  vertical-align: middle;
  @media (max-width: 1160px) {
    display: none;
  }
`;
interface AppProps {
  handleShowModal: () => void;
  numberOfCartItem: number;
}
interface ShowNav {
  show: boolean;
}

const Header2 = ({ handleShowModal, numberOfCartItem }: AppProps) => {
  const [showNav, setShowNav] = React.useState(true);
  const handleShowNav = () => setShowNav(!showNav);
  return (
    <Container>
      <Navbar>
        <LogoContainer href="https://www.lab.biologix.com.ng/">
          <Logo src={logo} alt="biologixLogo" height="50" />
        </LogoContainer>
        <NavContainer show={showNav}>
          <NavLink href="https://www.lab.biologix.com.ng/">Home</NavLink>
          <NavLink href="https://www.lab.biologix.com.ng/about-us">
            About Us
          </NavLink>
          <NavLink href="https://www.lab.biologix.com.ng/blog">Blog</NavLink>
          <NavLink href="https://www.lab.biologix.com.ng/about-us">
            Contact Us
          </NavLink>
          <NavLink href="https://packages.lab.biologix.com.ng/">
            Health Plan / Packages
          </NavLink>
          <NavLink href="https://www.lab.biologix.com.ng/faqs">FAQs</NavLink>
        </NavContainer>

        <ButtonContainer>
          <StyledButton
            className="btn btn-danger me-3"
            href="https://biologix.com.ng"
          >
            Visit Biologix Support Services
          </StyledButton>
          <BarsContainer onClick={() => handleShowNav()}>
            {showNav ? <FaBars /> : <FaTimes />}
          </BarsContainer>
          <NotificationContainer
            style={{ fontSize: "2rem" }}
            onClick={() => handleShowModal()}
          >
            <MdOutlineShoppingCart />
            <Badge>{numberOfCartItem}</Badge>
          </NotificationContainer>
        </ButtonContainer>
      </Navbar>
    </Container>
  );
};

export default Header2;
