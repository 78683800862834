import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import WomenHealth from "./components/WomenHealth";

import Checkout from "./components/CheckoutForm";
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [],
  },
  {
    path: "checkout",
    element: <Checkout />,
  },
]);

export default router;
