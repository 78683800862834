import React from "react";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";
import { TestContainer, TestTitle } from "./Card2";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { AddToCartButton } from "./Card2";
import image from "../logoBio.png";
import { Cart } from "../App";
import CheckoutForm from "./CheckoutForm";
import { FaDotCircle } from "react-icons/fa";

interface Show {
  show: boolean;
}
const Container = styled.div<Show>`
  position: ${(props) => (props.show ? "fixed" : "absolute")};
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.03);
  z-index: 3;
  left: ${(props) => (props.show ? "0" : "100%")};
  transition: 0.5s ease-in-out;
  top: 0;
  @media (max-width: 768px) {
    width: 100%;
    display: block;
  }
`;
const CartContainer = styled.div<Show>`
  height: 100%;
  width: 90%;
  position: relative;
  background: white;
  transition: 1s ease;
  overflow: scroll;
  z-index: 3;
  left: 10%;
  overflow-y: scroll !important;
  @media (max-width: 768px) {
    width: 100%;
    display: block;
    left: 0;
    padding-bottom: 40px;
  }
`;
const MainContainer = styled.div`
  position: relative;
  @media (max-width: 768px) {
    display: grid;
  }
`;
export const ExitButton = styled.i`
  font-size: 20px;
  color: #003766;
  cursor: pointer;
  &:hover {
    color: #5390c5;
  }
`;
const ContainerHeader = styled.div`
  padding: 10px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5555;
  background-color: white;
`;
const TitleContainer = styled.div`
  padding: 20px;
  width: 100%;
  margin-top: 50px;
`;
const Title = styled.h1`
  color: #003766;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  padding-bottom: 15px;
  text-align: left;
`;
const Divider = styled.div`
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 15px;
`;
const InlineDivider = styled(Divider)`
  @media (min-width: 768px) {
    display: none;
  }
`;
const CartOrderItemContainer = styled.div`
  position: relative;
  width: 60%;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 100%;
    display: block;
  }
`;
const CartItemContainer = styled.div`
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    display: block;
  }
`;
const OrderSummaryContainer = styled.div<Show>`
  display: flex;
  position: sticky;
  position: fixed;
  flex-direction: column;
  width: 30%;
  padding: 20px;
  border-radius: 20px;
  right: ${(props) => (props.show ? "20px" : "-100%")};
  top: 28%;
  transition: 0.3s ease-in-out;
  /* background-color: #f2f8fb; */
  background-color: #fff7f7;

  @media (max-width: 768px) {
    width: 90%;
    position: relative;
    left: 0;
    right: 0;
    bottom: 20px;
    justify-self: center;
  }
`;
const TestListContainer = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    display: grid;
    justify-content: right;
  }
`;
const CartItem = styled.div`
  display: grid;
  padding: 0;
  width: 100%;

  @media (max-width: 768px) {
    display: flex;
    justify-content: right;
    flex-direction: column;
  }
`;
const QuantityMainContainer = styled.div`
  display: flex;
  align-items: first baseline;
  width: 100%;
  justify-content: center;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`;
const QuantityTag = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;
const CartItemTitle = styled.h2`
  color: #003766;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  padding-bottom: 15px;
  text-align: left;
  padding-bottom: 0;
  @media (min-width: 768px) {
    font-size: 14px;
  }
`;
const QuantityContainer = styled.div`
  display: flex;
  column-gap: 15px;

  @media (max-width: 768px) {
    justify-content: right;
  }
`;
const ArrowUp = styled.i`
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 1;
  letter-spacing: 0.275px;
  color: #ed4e4e;
  cursor: pointer;
  &:hover {
    color: #a41111;
  }
`;
const Quantity = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.275px;
  color: #003766;
  align-self: center;
`;
const PriceContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const PriceTag = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const Price = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.275px;
  color: #0077ae;
  @media (max-width: 768px) {
    text-align: right;
  }
`;
const DeleteIcon = styled.i`
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 1;
  letter-spacing: 0.275px;
  color: #ed4e4e;
  transition: color 0.4s ease;
  cursor: pointer;
  &:hover {
    color: #9b2525;
  }
  @media (max-width: 768px) {
    display: flex;
    justify-content: right;
  }
`;
const ProductContainer = styled.div`
  display: flex;
`;
const Product = styled.p`
  display: block;
  @media (min-width: 768px) {
    display: none;
    background-color: red;
  }
`;
const TestContainerItem = styled(TestContainer)``;
const SummaryTitle = styled(Title)`
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
`;
const SubTotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Subtotal = styled(Title)`
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  text-transform: capitalize;
`;
const SubTotalPrice = styled(Subtotal)`
  font-weight: 400;
`;

const CouponContainer = styled.div`
  padding: 20px 0;
`;
const InputContainer = styled.div`
  display: flex;
  width: 100%;
`;
const TotalSummaryContainer = styled.div`
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
`;
const Input = styled.input`
  width: 100%;
  outline: none;
  border: 1px solid #f6f6f6;
  height: 30px;
  font-family: Roboto, serif;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 16px;
  text-align: left;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  padding-left: 10px;
  padding-right: 10px;
`;
const CouponButton = styled.button`
  width: 100%;
  background-color: #ed4e4e;
  outline: none;
  border: none;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.17px;
  line-height: normal;
  text-align: center;
  height: 28px;
  border-radius: 3px !important;
  min-height: 28px !important;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
`;
const Total = styled(SubTotalPrice)`
  font-weight: 600;
`;
const TotalPrice = styled(Title)``;

const Image = styled.img``;
interface AppProps {
  handleShowModal: () => void;
  show: boolean;
  cartData: Cart[];
  removeFromCart: (id: number, title: string, category: string) => void;
  decreaseQuantity: (cartItem: Cart) => void;
  increaseQuantity: (cartItem: Cart) => void;
  totalPrice: number;
  setCart: React.Dispatch<React.SetStateAction<Cart[]>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  publicKey: string;
  setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

const CartModal = ({
  handleShowModal,
  show,
  cartData,
  removeFromCart,
  decreaseQuantity,
  increaseQuantity,
  totalPrice,
  setCart,
  publicKey,
  setShowModal,
  setShowSuccess,
}: AppProps) => {
  const [showCheckout, setShowCheckout] = React.useState<boolean>(true);
  const [customPrice, setCustomPrice] = React.useState<number>(0);
  const addCustomPriceToCart = (id: number) => {
    const newCart = [...cartData];
    const newPrice = newCart.find((item) => item.id === id);
    if (newPrice) {
      newPrice.totalPrice = customPrice;
      setCart(newCart);
    }
  };
  return (
    <Container show={show}>
      <CartContainer show={show}>
        <ContainerHeader>
          <ExitButton onClick={handleShowModal}>
            <FaTimes />
          </ExitButton>
          {/* <div>
            <Image src={image} alt="Logo" height="50" />
          </div> */}
        </ContainerHeader>

        {showCheckout ? (
          <>
            <TitleContainer>
              <Title>Shopping Cart</Title>
            </TitleContainer>
            {cartData.length > 0 ? (
              <>
                <MainContainer style={{ position: "relative" }}>
                  <CartOrderItemContainer>
                    {cartData.map((item, index) => (
                      <React.Fragment key={index}>
                        <Divider />
                        <CartItemContainer>
                          <CartItem>
                            <ProductContainer
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span style={{ marginRight: "5px" }}>
                                {index + 1}.
                              </span>{" "}
                              <Product>Product</Product>
                              <CartItemTitle>
                                {item.price === 0
                                  ? "Custom Gift Card"
                                  : item.title}{" "}
                              </CartItemTitle>
                            </ProductContainer>
                            <TestListContainer>
                              {item.listOfTest &&
                                item.listOfTest.map((item, index) => (
                                  <TestContainerItem>
                                    <FaDotCircle style={{ color: "#ed4e4e" }} />
                                    <TestTitle>{item}</TestTitle>
                                  </TestContainerItem>
                                ))}
                            </TestListContainer>
                          </CartItem>
                          <InlineDivider />
                          <QuantityMainContainer>
                            <QuantityTag>Quantity</QuantityTag>
                            <QuantityContainer>
                              <ArrowUp onClick={() => increaseQuantity(item)}>
                                {item.quantity && item.quantity > 0 && (
                                  <AiFillPlusCircle />
                                )}
                              </ArrowUp>
                              <Quantity>{item.quantity}</Quantity>
                              <ArrowUp onClick={() => decreaseQuantity(item)}>
                                {item.quantity && item.quantity > 1 && (
                                  <AiFillMinusCircle />
                                )}
                              </ArrowUp>
                            </QuantityContainer>
                          </QuantityMainContainer>
                          <InlineDivider />
                          <PriceContainer>
                            <PriceTag>SubTotal</PriceTag>
                            <Price>
                              {item.totalPrice && item.totalPrice > 0 ? (
                                item.totalPrice.toLocaleString()
                              ) : (
                                <div
                                  style={{ display: "flex", columnGap: "5px" }}
                                >
                                  <input
                                    type="number"
                                    value={customPrice}
                                    onChange={(e) =>
                                      setCustomPrice(parseInt(e.target.value))
                                    }
                                    style={{ width: "80px" }}
                                  />
                                  <button
                                    style={{
                                      padding: "4px 8px",
                                      border: "1px solid #ed4e4e",
                                      borderRadius: "5px",
                                      fontSize: "12px",
                                      color: "white",
                                      fontWeight: "700",
                                      background: "#ed4e4e",
                                    }}
                                    onClick={() =>
                                      addCustomPriceToCart(item.id)
                                    }
                                  >
                                    {" "}
                                    Add Price{" "}
                                  </button>
                                </div>
                              )}
                            </Price>
                          </PriceContainer>
                          <InlineDivider />
                          <DeleteIcon
                            onClick={() => {
                              item.id &&
                                removeFromCart(
                                  item.id,
                                  item.title,
                                  item.category
                                );
                            }}
                          >
                            <MdDelete />
                          </DeleteIcon>
                        </CartItemContainer>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </CartOrderItemContainer>

                  <OrderSummaryContainer show={show}>
                    <SummaryTitle>Order Summary</SummaryTitle>
                    <SubTotalContainer>
                      <Subtotal style={{}}>SubTotal:</Subtotal>
                      <SubTotalPrice>
                        &#8358;{totalPrice && totalPrice.toLocaleString()}
                      </SubTotalPrice>
                    </SubTotalContainer>
                    <CouponContainer>
                      <div style={{ display: "flex", width: "100%" }}>
                        <InputContainer>
                          <Input type="text" placeholder="enter coupon code" />
                        </InputContainer>
                        <div>
                          <CouponButton> Add</CouponButton>
                        </div>
                      </div>
                    </CouponContainer>
                    <Divider />
                    <TotalSummaryContainer>
                      <Total>Total</Total>
                      <TotalPrice>
                        &#8358;{totalPrice.toLocaleString()}
                      </TotalPrice>
                    </TotalSummaryContainer>
                    <AddToCartButton
                      active
                      onClick={() => setShowCheckout(false)}
                    >
                      Checkout
                    </AddToCartButton>
                  </OrderSummaryContainer>
                </MainContainer>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "100%",
                  width: "100%",

                  justifyContent: "center",
                }}
              >
                <i style={{ fontSize: "5rem", color: "#003766" }}>
                  <MdOutlineRemoveShoppingCart />
                </i>
              </div>
            )}
          </>
        ) : (
          <CheckoutForm
            showCheckout={showCheckout}
            totalPrice={totalPrice}
            setShowCheckout={setShowCheckout}
            cart={cartData}
            setCart={setCart}
            setShowModal={setShowModal}
            publicKey={publicKey}
            setShowSuccess={setShowSuccess}
          />
        )}
      </CartContainer>
    </Container>
  );
};

export default CartModal;
