import React, { useState } from "react";
import styled from "styled-components";
import { PaystackButton } from "react-paystack";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactForm from "react-bootstrap/Form";
import { Cart } from "../App";
import axios from "axios";
import SuccessModal from "./SuccessModal";
import PaystackPop from "@paystack/inline-js";
// const Container = styled.div<Show>`
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   overflow: scroll;
//   background: rgba(0, 0, 0, 0.1);
//   left: ${(props) => (props.show ? "0" : "100%")};
//   z-index: 5;
//   transition: 0.4s ease-in-out;
//   @media (max-width: 768px) {
//     width: 100%;
//     display: block;
//     left: 0;
//   }
// `;
const ModalContainer = styled.div`
  height: 100%;
  /* width: 90%;
  position: relative;
  background: white;
  transition: 1s ease;

  z-index: 3;
  left: 10%; */
  overflow-y: scroll !important;
  @media (max-width: 768px) {
    width: 100%;
    display: block;
    left: 0;
    margin: 0;
    overflow: hidden;
  }
`;
const ExitButton = styled.i`
  font-size: 20px;
  color: #003766;
  cursor: pointer;
  &:hover {
    color: #5390c5;
  }
`;

const TitleContainer = styled.div`
  padding: 20px;
  width: 100%;
  margin-top: 50px;
`;
const Title = styled.div`
  color: #003766;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.15px;
  text-transform: uppercase;

  text-align: left;
`;

const FormContainer = styled.div`
  width: 100%;
  padding: 25px 30px;
`;
const TitleHeader = styled.div`
  color: #003766;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  margin-bottom: 10px;
  &::after {
    content: "";
    position: absolute;
    height: 3px;
    width: 30px;
    background: #003766;
    left: 0;
    bottom: 0;
  }
`;
const Divider = styled.div`
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 15px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const FormGroup = styled.div`
  display: flex;
  width: 100%;
  column-gap: 20px;
  @media (max-width: 768px) {
    display: grid;
  }
`;
const PayButton = styled.button`
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0.17px;
  transition: background-color 0.4s;
  font-size: 14px;
  border-radius: 5px;
  width: 400px;
  height: 40px;

  justify-content: center;
  align-items: center;
  display: flex;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
  background-color: #3bb75e;
  &:hover {
    background-color: #d04949;
  }
`;
const UserDetails = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const InputBox = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;
const Label = styled.span`
  font-size: 14px;
  color: #003766;
  display: block;
  font-weight: 500;
  margin-left: 5px;
`;
const GiftContainer = styled.div`
  height: 60px;

  background: rgba(245, 238, 238, 0.5);
  padding-left: 20px;
  align-items: center;
  display: flex;

  margin-bottom: 10px;
`;
const Input = styled.input`
  height: 40px;
  width: 100%;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;

  outline: none;
  &::placeholder {
    font-size: 14px;
  }
`;
const BackButton = styled.button`
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.17px;
  transition: background-color 0.4s;
  font-size: 14px;
  border-radius: 5px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  outline: none;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  color: white;
  background-color: #ed4e4e;
  &:hover {
    background-color: #d04949;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
  }
`;

interface AppProps {
  showCheckout: boolean;
  totalPrice: number;
  setShowCheckout: React.Dispatch<React.SetStateAction<boolean>>;
  cart: Cart[];
  setCart: React.Dispatch<React.SetStateAction<Cart[]>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  publicKey: string;
  setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Show {
  show: boolean;
}

const CheckoutForm = ({
  showCheckout,
  totalPrice,
  setShowCheckout,
  cart,
  setCart,
  setShowModal,
  publicKey,
  setShowSuccess,
}: AppProps) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const [recipientData, setRecipientData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const resetForm = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setPhone("");
    setRecipientData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    });
    setCart([]);
    setShowModal(false);
  };
  const plans = cart.map(
    (item) => item.quantity && item.title.repeat(item.quantity)
  );

  const handlePaymentSuccessful = (transactionRef: string) => {
    const params = {
      entityTypeId: "183",
      "fields[ufCrm10_1701208676]": plans,
      "fields[ufCrm10_1700133523]": email,
      "fields[ufCrm10_1700133820]": transactionRef,
      "fields[ufCrm10_1701162938]":
        recipientData.firstName + " " + recipientData.lastName,
      "fields[ufCrm10_1701162961]": firstName + " " + lastName,
      "fields[ufCrm10_1701207720]": totalPrice,
      "fields[ufCrm10_1701207965]": recipientData.email,
      //"fields[ufCrm10_1701210649]": numbersOfTests,
    };
    const baseUrl = `https://biologix.bitrix24.com/rest/1/5f3ljdn3s20oxad5/crm.item.add`;

    axios
      .get(baseUrl, { params: params })
      .then((response) => {})
      .catch((error) => console.log(error));
  };
  const handlePayWithPaystack = (event: any) => {
    event.preventDefault();
    try {
      const paystack = new PaystackPop();
      paystack.newTransaction({
        key: publicKey,
        email: email,
        amount: totalPrice * 100,
        lastname: lastName,
        firstname: firstName,

        onSuccess: (transaction: any) => {
          handlePaymentSuccessful(transaction.reference);
          resetForm();
        },
        onCancel: () => {},
      });
    } catch (error) {
      console.error();
    }
  };
  return (
    // <Container show={showCheckout}>
    <>
      <ModalContainer>
        <TitleContainer>
          <Title>Checkout</Title>
        </TitleContainer>
        <Divider />
        <FormContainer>
          <TitleHeader style={{ fontSize: "25px" }}>
            User Information
          </TitleHeader>
          <Divider />
          <Form onSubmit={handlePayWithPaystack}>
            <FormGroup>
              <UserDetails>
                <InputBox>
                  <Label>First Name</Label>
                  <Input
                    type="text"
                    required
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFirstName(e.target.value)
                    }
                  />
                </InputBox>
              </UserDetails>
              <UserDetails>
                <InputBox>
                  <Label>Last Name</Label>
                  <Input
                    type="text"
                    required
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setLastName(e.target.value)
                    }
                  />
                </InputBox>
              </UserDetails>
            </FormGroup>
            <FormGroup>
              <UserDetails>
                <InputBox>
                  <Label>email</Label>
                  <Input
                    type="email"
                    required
                    placeholder="Email"
                    value={email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setEmail(e.target.value)
                    }
                  />
                </InputBox>
              </UserDetails>
              <UserDetails>
                <InputBox>
                  <Label>Phone Number</Label>
                  <Input
                    type="number"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPhone(e.target.value)
                    }
                  />
                </InputBox>
              </UserDetails>
            </FormGroup>
            <FormGroup>
              <UserDetails style={{ width: "320px", marginBottom: "15px" }}>
                <Label>Test Location</Label>
                <ReactForm.Select aria-label="Default select example">
                  <option>Prefered Test Location</option>
                  <option value="Anthony">Anthony</option>
                  <option value="Lekki">Lekki</option>
                </ReactForm.Select>
              </UserDetails>
            </FormGroup>
            <GiftContainer>
              <input
                type="checkbox"
                style={{ width: "40px" }}
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
              />
              <span
                style={{
                  color: "#003766",
                  fontSize: "14px",
                  marginLeft: "5px",
                }}
              >
                is this a gift ?
              </span>
            </GiftContainer>
            {isChecked && (
              <>
                <FormGroup>
                  <UserDetails>
                    <InputBox>
                      <Label>Recipient First Name</Label>
                      <Input
                        type="text"
                        required
                        placeholder="Recipient First Name"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setRecipientData({
                            ...recipientData,
                            firstName: e.target.value,
                          })
                        }
                        value={recipientData.firstName}
                      />
                    </InputBox>
                  </UserDetails>
                  <UserDetails>
                    <InputBox>
                      <Label>Last Name</Label>
                      <Input
                        type="text"
                        required
                        placeholder="Recipient Last Name"
                        value={recipientData.lastName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setRecipientData({
                            ...recipientData,
                            lastName: e.target.value,
                          })
                        }
                      />
                    </InputBox>
                  </UserDetails>
                </FormGroup>
                <FormGroup>
                  <UserDetails>
                    <InputBox>
                      <Label>email</Label>
                      <Input
                        type="email"
                        required
                        placeholder="Recipient Email"
                        value={recipientData.email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setRecipientData({
                            ...recipientData,
                            email: e.target.value,
                          })
                        }
                      />
                    </InputBox>
                  </UserDetails>
                  <UserDetails>
                    <InputBox>
                      <Label>Phone Number</Label>
                      <Input
                        type="number"
                        placeholder="Recipient Phone Number"
                        value={recipientData.phone}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setRecipientData({
                            ...recipientData,
                            phone: e.target.value,
                          })
                        }
                      />
                    </InputBox>
                  </UserDetails>
                </FormGroup>
              </>
            )}
            <Divider />
            <ButtonContainer>
              <BackButton
                onClick={() => setShowCheckout(!showCheckout)}
                type="button"
              >
                Back To Cart
              </BackButton>

              {/* {publicKey && (
                <PaystackButton
                  className="paystack_button"
                  publicKey={publicKey}
                  email={email}
                  amount={totalPrice * 100}
                  lastname={lastName}
                  firstname={firstName}
                  text={`pay NGN ${totalPrice.toLocaleString()}`}
                  onSuccess={(props) => {
                    handlePaymentSuccessful(props.reference);
                    resetForm();
                  }}
                  onClose={() => {
                    setShowSuccess(false);
                  }}
                />
              )} */}
              <button type="submit" className="paystack_button">
                pay NGN {totalPrice.toLocaleString()}
              </button>
            </ButtonContainer>
          </Form>
        </FormContainer>
      </ModalContainer>
    </>
    // </Container>
  );
};

export default CheckoutForm;
