import React from "react";
import styled from "styled-components";
import { FaCartPlus } from "react-icons/fa";
import { AddToCartButtonContainer, AddToCartButton } from "./Card2";
import { Cart } from "../App";

const Container = styled.div`
  height: 500px;
  width: 100%;
  border: 1px solid #ececec;
  border-radius: 10px;
  transition: border-color 0.4s;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: calc(33.33333333% - 30px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  @media (max-width: 1160px) {
    width: calc(50% - 30px);
  }
  @media (max-width: 768px) {
    width: 350px;
  }

  &:hover {
    border-color: #0077ae;
    box-shadow: 0 1px 9px rgba(0, 119, 174, 0.28);
  }
`;
const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
const CardTitleContainer = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px;
  /* background-color: #f2f8fb; */
  background-color: #f6dada;

  max-height: 50px;
  display: flex;
  justify-content: space-between;
  color: rgba(1, 7, 12, 0.66);
`;
const CardTitle = styled.div`
  color: #003766;
  font-weight: 600;
`;
const Price = styled.div`
  color: #0077ae;
  color: #003766;

  font-weight: 400;
  margin-left: 10px;
`;
const ContainerImage = styled.div`
  padding-bottom: 10px;
`;
const ImageContainer = styled.div`
  height: 385px;
  width: 100%;
  border-bottom: 1px solid #ececec;
`;
const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;
const GiftCardButton = styled(AddToCartButton)<Active>`
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.17px;
  transition: background-color 0.4s;
  font-size: 14px;
  border-radius: 100px;
  height: 40px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  outline: none;
  border: none;

  cursor: ${(props) => (props.active ? "pointer" : "not-allowed")};
  color: white;
  background-color: ${(props) => (props.active ? "#ed4e4e;" : "#666")};
  &:hover {
    background-color: ${(props) => (props.active ? "#ae3a3a;" : "#666")};
  }
`;
interface Active {
  active: boolean;
}
type AppProps = {
  price: number;
  image: string;
  title: string;
  id: number;
  addToCart: (item: Cart, category: string) => void;
  category: string;
  selected: boolean;
};
const GiftCards = ({
  price,
  image,
  title,
  id,
  addToCart,
  category,
  selected,
}: AppProps) => {
  return (
    <Container>
      <CardContainer>
        <CardTitleContainer>
          <CardTitle>{title}</CardTitle>
          <Price>
            {price == 0 ? (
              "custom"
            ) : (
              <span>&#8358; {price.toLocaleString()}</span>
            )}
          </Price>
        </CardTitleContainer>
        <ContainerImage>
          <ImageContainer>
            <Image src={image} />
          </ImageContainer>
        </ContainerImage>

        <AddToCartButtonContainer style={{ marginBottom: "10px" }}>
          <GiftCardButton
            onClick={() => addToCart({ price, title, id, category }, category)}
            active={selected}
            disabled={!selected}
          >
            <span style={{ paddingRight: "10px" }}>
              <i>
                <FaCartPlus />
              </i>
            </span>
            Add To Cart
          </GiftCardButton>
        </AddToCartButtonContainer>
      </CardContainer>
    </Container>
  );
};

export default GiftCards;
