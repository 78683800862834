import React from "react";

import styled from "styled-components";
import Sidebar from "./Sidebar";
import { PackageT } from "../data";
import WomenHealth from "./WomenHealth";
import { Outlet } from "react-router-dom";
import Card2 from "./Card2";
import GiftCards from "./GiftCards";
import { GiftCard } from "./cardData";
import { Cart } from "../App";
import { MdOutlineNavigateNext } from "react-icons/md";
import MyPaginate from "./Pagination";
import { GoAlertFill } from "react-icons/go";
const Container = styled.div`
  display: flex;
  padding: 0;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;
const MainContainer = styled.div`
  width: 80%;
  padding-top: 100px;
  padding-bottom: 15px;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 0;
    display: grid;
  }
  @media (max-width: 448px) {
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;

    flex-direction: column;
  }
`;
const TestContainer = styled.div`
  display: flex;
  width: 100%;
  row-gap: 30px;
  flex-wrap: wrap;
  column-gap: 30px;
  padding: 15px;
  @media (max-width: 768px) {
    width: 60%;
  }
`;
const Title = styled.div`
  color: #003766;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.15px;
  text-transform: capitalize;
  padding-bottom: 10px;
  text-align: left;
  margin-left: 15px;
  @media (max-width: 768px) {
    align-self: flex-start;
  }
`;

type AppProps = {
  data: PackageT[];
  filterParameter: string;
  handleFilter: (parameter: string) => void;
  handleFilterForAllTag: () => void;
  handleFilterForGift: (parameter: string) => void;
  giftData: GiftCard[];
  active: boolean;
  addToCart: (item: Cart, category: string) => void;
  cartData: Cart[];
  itemOffSet: number;

  setItemOffset: React.Dispatch<React.SetStateAction<number>>;
};
const Main = ({
  data,
  handleFilter,
  handleFilterForAllTag,
  filterParameter,
  giftData,
  handleFilterForGift,
  active,
  addToCart,
  cartData,
  setItemOffset,
  itemOffSet,
}: AppProps): React.JSX.Element => {
  const [activeButton, setActiveButton] = React.useState(false);

  const itemsPerPage = 6;
  const endOfOffset = itemOffSet + itemsPerPage;
  const currentItems = data.slice(itemOffSet, endOfOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    window.scrollTo(0, 0);
    setItemOffset(newOffset);
  };
  console.log(giftData);
  return (
    <Container>
      <Sidebar
        handleFilterForGift={handleFilterForGift}
        handleFilter={handleFilter}
        filterParameter={filterParameter}
        handleFilterForAllTag={handleFilterForAllTag}
      />
      <MainContainer>
        <Title>{filterParameter}</Title>
        <TestContainer>
          {active && active ? (
            <>
              {currentItems.length > 0 ? (
                <>
                  {currentItems.map((item, index) => (
                    <Card2
                      key={index}
                      price={item.price}
                      description={item.testDescription}
                      title={item.packageName}
                      listOfTest={item.listOfTest}
                      addToCart={addToCart}
                      cartData={cartData}
                      discountTag={item.discountTag ? item.discountTag : false}
                      discount={item.discount ? item.discount : 0}
                      id={item.id}
                      selected={item.selected ? item.selected : false}
                      category={item.packageCategory}
                      tag={item.tag ? item.tag : ""}
                    />
                  ))}
                </>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <h1 style={{ color: "#003766" }}>No test Available Now</h1>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {giftData.map((item, index) => (
                <GiftCards
                  key={index}
                  price={item.price}
                  image={item.image}
                  title={item.cardTitle}
                  addToCart={addToCart}
                  id={item.id}
                  category={item.cardTitle}
                  selected={item.selected}
                />
              ))}
            </>
          )}
        </TestContainer>
        <div style={{}}>
          <MyPaginate
            pageCount={pageCount}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
          />
        </div>

        {currentItems.length > 0 && (
          <div
            style={{
              display: "flex",
              columnGap: "15px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <GoAlertFill style={{ color: "#ed4e4e" }} />
            <span style={{ color: "#003766" }}>
              Please note that the prices mentioned for tests above is subject
              to change without prior notice
            </span>
          </div>
        )}
      </MainContainer>
    </Container>
  );
};

export default Main;
