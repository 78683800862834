import React from "react";
import styled from "styled-components";

import { NavLink } from "react-router-dom";
import { PackageTests, PackageT } from "../data";
import giftCardData from "./cardData";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  padding-top: 100px;
  width: 20%;
  margin-bottom: 0;

  @media (max-width: 768px) {
    display: block;
    background-color: inherit;
    margin-bottom: 20px;
  }
`;
const ContainerHeaderContainer = styled.div`
  display: flex;
  padding-left: 20px;
`;

const FilterLabel = styled.h5`
  color: #003766;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.15px;
  text-transform: capitalize;
  padding-bottom: 15px;
  text-align: left;
`;

const Label = styled.div<Active>`
  text-align: left;
  border: 1px solid #ececec;
  cursor: pointer;
  transition: border-color 0.4s;
  padding: 10px;
  padding-left: 20px;
  background-color: ${(props) => props.active && "#f6dada"};

  &:hover {
    border-color: #0077ae;
    box-shadow: 0 1px 9px rgba(0, 119, 174, 0.28);
  }
  @media (max-width: 768px) {
    font-size: 10px;
    border-radius: 40px;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    white-space: nowrap;
    align-items: start;
    padding: 0 10px;
  }
`;
const Categories = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: flex;
    column-gap: 10px;
    overflow-x: scroll;
    width: 100vw;

    & > div:first-child {
      width: 200px;
    }
  }
`;
const Item = styled.label`
  display: flex;
  column-gap: 5px;
  font-size: 14px;
  margin-bottom: 15px;
  color: #003766;
  height: 100%;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  width: 100%;
  @media (max-width: 768px) {
    font-size: 12px;
    display: block;
    padding: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    padding: 10px;
    & > span {
      display: none;
    }
  }
`;
interface Active {
  active: boolean;
}
type AppProps = {
  handleFilter: (parameter: string) => void;
  handleFilterForAllTag: () => void;

  filterParameter: string;
  handleFilterForGift: (filter: string) => void;
};

const Sidebar = ({
  handleFilter,
  handleFilterForAllTag,
  handleFilterForGift,
  filterParameter,
}: AppProps) => {
  const sidebarData = PackageTests.map((item) => item.packageCategory);
  const sidebarDataFiltered = sidebarData.filter(function (x, i, a) {
    return a.indexOf(x) === i;
  });
  const handleFilterCount = (parameter: string) => {
    let initialData = PackageTests.filter(
      (item) => item.packageCategory === parameter
    );
    const count = initialData.length;
    return count;
  };

  return (
    <Container>
      <ContainerHeaderContainer>
        <FilterLabel>Categories</FilterLabel>
      </ContainerHeaderContainer>
      <Categories>
        <Label
          onClick={() => handleFilterForAllTag()}
          active={filterParameter === "All Packages" && true}
        >
          <div style={{ display: "flex" }}>
            <Item>
              All Packages <span>({sidebarData.length})</span>
            </Item>
          </div>
        </Label>
        <Label
          onClick={() => handleFilter("Women's Health")}
          active={filterParameter === "Women's Health" && true}
        >
          <Item>
            {"Women's Health"}
            <span>({handleFilterCount("Women's Health")})</span>
          </Item>
        </Label>
        <Label
          onClick={() => handleFilter("Men's Health")}
          active={filterParameter === "Men's Health" && true}
        >
          <Item>
            {"Men's Health"}
            <span>({handleFilterCount("Men's Health")})</span>
          </Item>
        </Label>
        <Label
          onClick={() => handleFilter("Sexual Health")}
          active={filterParameter === "Sexual Health" && true}
        >
          <Item>
            {"Sexual Health"}
            <span>({handleFilterCount("Sexual Health")})</span>
          </Item>
        </Label>
        <Label
          active={filterParameter === "General Wellness" && true}
          onClick={() => handleFilter("General Wellness")}
        >
          <Item>
            General Wellness
            <span>({handleFilterCount("General Wellness")})</span>
          </Item>
        </Label>
        <Label
          onClick={() => handleFilter("Toxicology")}
          active={filterParameter === "Toxicology" && true}
        >
          <Item>
            Toxicology<span>({handleFilterCount("Toxicology")})</span>
          </Item>
        </Label>
        <Label
          onClick={() => handleFilter("Specialized Genetic Tests")}
          active={filterParameter === "Specialized Genetic Tests" && true}
        >
          <Item>
            Specialized Genetic Tests{" "}
            <span>({handleFilterCount("Specialized Genetic Tests")})</span>
          </Item>
        </Label>
        {/* {sidebarDataFiltered.map((item, index) => (
          <Label
            key={index}
            onClick={() => handleFilter(item)}
            active={filterParameter === item && true}
          >
            <Item>
              {item} <span>({handleFilterCount(item)})</span>
            </Item>
          </Label>
        ))} */}
        <Label
          active={filterParameter === "Gift Cards" && true}
          onClick={() => handleFilterForGift("Gift Cards")}
        >
          <Item>
            Gift Cards
            <span>({giftCardData.length})</span>
          </Item>
        </Label>
      </Categories>
    </Container>
  );
};

export default Sidebar;
