export interface packageTests {
  packageName: string;
  description: string;
  price: number;
  listOfTest: string[];
}

export interface PackageT {
  listOfTest: string[];
  packageName: string;
  testDescription: string;
  price: number;
  packageCategory: string;
  discount?: number;
  discountTag?: boolean;
  id: number;
  selected?: boolean;
  tag?: string;
}

export const PackageTests: PackageT[] = [
  {
    id: 1,
    selected: true,
    listOfTest: ["Pap Smear", "HPV DNA"],
    packageCategory: "Women's Health",
    testDescription:
      "To sustain a healthy lifestyle and avert delayed diagnoses, particularly for cervical cancer, these suggested tests are the ideal examinations to get screened for a life free from cervical cancer",
    packageName: "Cervix Health ",
    price: 66810,
  },
  {
    id: 2,
    selected: true,
    listOfTest: [
      "Follicle stimulating hormone",
      "Luteinizing hormone",
      "Estradiol",
    ],
    packageCategory: "Women's Health",
    testDescription:
      "Tests such as follicle-stimulating hormone, luteinizing hormone, and estradiol help to inform about hormonal balance ",
    packageName: "Menopausal Screening ",
    price: 24250,
  },
  {
    id: 3,
    selected: true,
    listOfTest: ["Progesterone", "Luteinizing Hormone "],
    packageCategory: "Women's Health",
    testDescription:
      "These tests track ovulation effectively by measuring progesterone levels. ",
    price: 14805,
    packageName: "Ovulation Test",
  },
  {
    id: 4,
    selected: true,
    listOfTest: [
      "Follicle stimulating hormone",
      "Anti-Mullerian Hormone ",
      "Estradiol",
      "Luteinizing Hormone ",
      "Vitamin D",
      "Progesterone",
      "Blood group and Rh",
      "TSH",
    ],
    packageCategory: "Women's Health",
    testDescription:
      "comprehensive panel including follicle-stimulating hormone, anti-Mullerian hormone, estradiol, luteinizing hormone, vitamin D, progesterone, blood group and Rh, and TSH. This test package enhances the understanding for informed family planning.",
    price: 104560,
    packageName: "Fertility Panel ",
  },
  {
    id: 5,
    selected: true,
    listOfTest: [
      "DHEAS",
      "Follicle stimulating hormone",
      "Luteinizing hormone",
      "Estradiol",
      "Prolactin",
      "Testosterone",
      "Thyroid stimulating hormone",
      "Vitamin D",
      "Full Blood Count",
      "Blood group and Rh",
      "Progesterone",
    ],
    packageCategory: "Women's Health",
    testDescription:
      "Tests to address female infertility concerns with a comprehensive panel encompassing DHEAS, follicle-stimulating hormone, estradiol, luteinizing hormone, prolactin, testosterone, thyroid-stimulating hormone, full blood count, vitamin D, progesterone, and blood group/Rh. ",
    price: 67562.76,
    packageName: "Female Infertility Panel ",
  },
  {
    id: 6,
    selected: true,
    listOfTest: ["Hepatitis B", "Hepatitis C", " HIV 1&2 ", "VDRL"],
    packageCategory: "Sexual Health",
    testDescription:
      "For a thorough female STI screening, get tested for hepatitis B and C",
    packageName: "LadyGarden  Women Care",
    price: 15337,
  },
  {
    id: 7,
    selected: true,
    packageName: "LadyGarden Women Care Plus ",
    packageCategory: "Sexual Health",
    listOfTest: [
      "Hepatitis B",
      "Hepatitis C",
      "HIV 1&2 ",
      "Chlamydia",
      "HSV 1 & 2",
      "VDRL",
    ],
    price: 29123,
    testDescription:
      "Prioritize your sexual health with a comprehensive female STI panel, covering hepatitis B and C, HIV 1 & 2, Chlamydia, HSV 1 & 2 screening, HVS, and VDRL. Be informed and take proactive steps for a healthier, confident lifestyle.",
  },
  {
    id: 8,
    selected: true,
    listOfTest: [
      "Hepatitis B",
      "Hepatitis C",
      "HIV 1&2",
      "Chlamydia",
      "HSV 1 & 2",
      "HVS",
      "VDRL",
      "HPV-DNA",
    ],
    packageCategory: "Sexual Health",
    testDescription:
      "Improve your sexual health awareness with a thorough female STI panel, encompassing hepatitis B and C, HIV 1 & 2, Chlamydia, HPV-DNA, HVS (Herpes Simplex Virus) type 1 IgG Ab, HVS type 2 IgG Ab, and VDRL.",
    packageName: " LadyGarden Women Care Max",
    price: 96059,
  },
  {
    id: 9,
    selected: true,
    listOfTest: ["PSA"],
    packageCategory: "Men's Health",
    testDescription:
      " To promote a healthy lifestyle and minimize the risk of delayed diagnosis, especially concerning prostate health, PSA is a recommended screening test for males.",
    packageName: "Prostrate Health",
    price: 14076,
  },
  {
    id: 10,
    selected: true,
    listOfTest: [
      "Albumin",
      "FSH",
      "LH",
      "Testosterone",
      "Fasting Glucose",
      "E2",
    ],
    packageCategory: "Men's Health",
    testDescription:
      "Optimize your male fertility health with a comprehensive test panel including albumin, FSH, LH, testosterone, fasting glucose, and E2 (estradiol). Stay informed about key factors affecting fertility for a proactive approach to reproductive health.",
    packageName: "Male Fertility Panel",
    price: 36280,
  },

  {
    id: 11,
    selected: true,
    listOfTest: [
      "Zinc",
      "Full Blood Count",
      "Ferritin",
      "C Reactive Protein",
      "Creatine Kinase",
      "Lactate Dehydrogenase",
      "Magnesium",
      "TSH",
      "Total Cholesterol",
      "HDL",
      "LDL",
      "VLDL",
      "Triglycerides",
    ],
    packageCategory: "Men's Health",
    testDescription:
      "Unlock your peak performance with our FitMen Health Package, designed exclusively for men who prioritize their well-being. This package goes beyond the surface, extending into key aspects of men's health",
    packageName: "Fit Men ",
    price: 95166,
  },
  {
    id: 12,
    selected: true,
    listOfTest: [
      "Zinc",
      "Full Blood Count",
      "Ferritin",
      "C Reactive Protein",
      "Creatine Kinase",
      "Lactate Dehydrogenase",
      "Magnesium",
      "TSH",
      "Vitamin B12",
      "Total Cholesterol",
      "HDL",
      "LDL",
      "VLDL",
      "Triglycerides",
      "Calcium",
    ],
    packageCategory: "Men's Health",
    testDescription:
      "Unlock your peak performance with our FitMen Health Package, designed exclusively for men who prioritize their well-being. This comprehensive package goes beyond the surface, delving into key aspects of men's health",
    packageName: "Fit Men Max ",
    price: 106170,
  },
  {
    id: 13,
    selected: true,
    listOfTest: ["Hepatitis B", "Hepatitis C", "HIV 1&2", "VDRL"],
    packageCategory: "Sexual Health",
    testDescription:
      "Prioritize your sexual health with a focused male STI panel covering hepatitis B and C, HIV 1 & 2, and VDRL. Stay informed and take proactive steps to ensure a healthy sexual lifestyle",
    packageName: "Hercules Men",
    price: 15337,
  },

  {
    id: 14,
    selected: true,
    listOfTest: [
      "Hepatitis B",
      "Hepatitis C",
      "HIV 1&2",
      "HSV1&2 screening",
      "Urethral swab",
      "VDRL",
    ],
    packageCategory: "Sexual Health",
    testDescription:
      "This sexual health test for male is a comprehensive male STI panel, covering hepatitis B and C, HIV 1 & 2, Chlamydia, along with microscopy urethral swabs. Maintain a healthy sexual lifestyle by taking these tests routinely.",
    packageName: "Hercules Men Plus ",
    price: 39.959,
  },

  // {
  //   id: 15,
  //   selected: true,
  //   listOfTest: [
  //     "Hepatitis B",
  //     "Hepatitis C",
  //     "HIV 1&2",
  //     "Chlamydia",
  //     "Herpes Simplex Virus Type 1  IgG Ab",
  //     "Herpes Simplex Virus Type 2  IgG Ab",
  //     "VDRL",
  //     "Urethral swab",
  //   ],
  //   packageCategory: "Sexual Health",
  //   testDescription:
  //     "Prioritize your sexual health with a comprehensive male STI panel, covering hepatitis B and C, HIV 1 & 2, Chlamydia, along with assessments for calcium, ESR, urinalysis, and BMI. Stay proactive in maintaining overall well-being and addressing key health markers.",
  //   packageName: "Hercules Men Max ",
  //   price: 45173.76,
  // },

  //General Wellness Test

  {
    id: 28,
    selected: true,
    listOfTest: [
      "Alanine Aminotransferase (ALT)",
      "Alkaline Phosphatase (ALP)",
      "Aspartate Aminotransferase (AST)",
      "Gamma-Glutamyltransferase (GGT)",
      "Total Bilirubin",
      "Albumin",
      "Ferritin",
    ],
    packageCategory: "General Wellness",
    testDescription:
      "This comprehensive test panel assesses liver health. Vital tests for key indicators such as alanine, aminotransferase, alkaline phosphatase, aspartate aminotransferase, gamma-glutamyltransferase, total bilirubin, and ferritin.",
    packageName: "Liver Health Tests",
    price: 22083,
  },
  // comprehensive Test Packages
  {
    id: 29,
    selected: true,
    listOfTest: [
      "Full Blood Count",
      "Electrolyte",
      "Bicarbonate",
      "Urea",
      "Creatinine",
      "eGFR",
      "SGPT(ALT)",
      "Alkaline Phosphate (ALP)",
      "SGOT (AST)",
      "Total Bilirubin",
      "Albumin",
      "Blood Sugar",
      "Total Cholesterol",
      "HDL",
      "LDL",
      "VLDL",
      "Triglycerides",
      "Urinalysis",
      "ESR",
      "Stool Analysis",
      "TSH",
      "T3",
      "T4",
      "Calcium",
      "Phosphorus",
      "HbsAg",
      "HIV 1 and 2 Screening",
      "Uric Acid",
      "BMI",
    ],
    packageCategory: "General Wellness",
    testDescription: "",
    packageName: "Men Wellness Check: Basic",
    price: 61450,
  },

  // {
  //   id: 30,
  //   selected: true,
  //   listOfTest: [
  //     "Full Blood count",
  //     "Renal Function test",
  //     "Liver Function test",
  //     "Blood sugar",
  //     "Lipid profile",
  //     "Urinalysis",
  //     "ESR",
  //     "Stool analysis",
  //     "Thyroid Function test",
  //     "Calcium",
  //     "Phosphorus",
  //     "HbsAg",
  //     "HIV 1 & 2 screening",
  //     "Uric Acid",
  //     "BMI",
  //     "Hepatitis C screening",
  //   ],
  //   packageCategory: "Men's Health",
  //   testDescription: "",
  //   packageName: "General Comprehensive Wellness Test: Silver",
  //   price: 57955.38,
  // },
  {
    id: 31,
    selected: true,
    listOfTest: [
      "Full Blood Count",
      "Electrolytes",
      "Bicarbonate",
      "urea",
      "Creatinine",
      "eGFR",
      "SGP (ALT)",
      "Alkaline Phosphate (ALP)",
      "SGOT (AST)",
      "Total Bilirubin",
      "Albumin",
      "Blood Sugar",
      "Total Cholesterol",
      "HDL",
      "LDL",
      "VLDL",
      "Triglycerides",
      "Urinalysis",
      "ESR",
      "Stool Analysis",
      "TSH",
      "T3",
      "T4",
      "Calcium",
      "Phosphorus",
      "HbaAG",
      "HIV 1 & 2 Screening",
      "Uric Acid",
      "BMI",
      "Hepatitis C Screening",
      "PSA",
      "Hba1c",
    ],
    packageCategory: "General Wellness",
    testDescription: "",
    packageName: "Men Wellness Check: Gold",
    price: 91715,
  },
  // {
  //   id: 32,
  //   selected: true,
  //   listOfTest: [
  //     "Full Blood count",
  //     "Renal Function test",
  //     "Liver Function test",
  //     "Blood sugar",
  //     "Lipid profile",
  //     "Urinalysis",
  //     "ESR",
  //     "Stool analysis",
  //     "Thyroid Function test",
  //     "Calcium",
  //     "Phosphorus",
  //     "HbsAg",
  //     "HIV 1 & 2 screening",
  //     "Uric Acid",
  //     "BMI",
  //     "Hepatitis C screening",
  //     "PSA",
  //     "HbA1C",
  //   ],
  //   packageCategory: "Men's Health",
  //   testDescription: "",
  //   packageName: "General Comprehensive Wellness Test: Diamond",
  //   price: 83210.58,
  // },
  {
    id: 33,
    selected: true,
    listOfTest: [
      "Full Blood Count",
      "Electrolytes",
      "Bicarbonate",
      "Urea",
      "Creatinine",
      "eGFR",
      "SGPT (ALT)",
      "Alkaline Phosphate (ALT)",
      "SGOT (AST)",
      "Total Bilirubin",
      "Albumin",
      "Blood Sugar",
      "Total Cholesterol",
      "HDL",
      "LDL",
      "VLDL",
      "Triglycerides",
      "Urinalysis",
      "ESR",
      "Stool Analysis",
      "TSH",
      "T3",
      "T4",
      "Calcium",
      "Phosphorus",
      "HbsAG",
      "HIV 1 & 2 Screening",
      "Uric Acid",
      "BMI",
      "Hepatitis C Screening",
      "PSA",
      "Hba1c",
      "Vitamin B12",
      "AFP",
    ],
    packageCategory: "General Wellness",
    testDescription: "",
    packageName: "Men Wellness Check: Platinum",
    price: 118213,
  },
  // women Comprehensive tests
  {
    id: 34,
    selected: true,
    listOfTest: [
      "Full Blood Count",
      "Electrolyte",
      "Bicarbonate",
      "Urea",
      "Creatinine",
      "eGFR",
      "SGPT(ALT)",
      "Alkaline Phosphate (ALP)",
      "SGOT (AST)",
      "Total Bilirubin",
      "Albumin",
      "Blood Sugar",
      "Total Cholesterol",
      "HDL",
      "LDL",
      "VLDL",
      "Triglycerides",
      "Urinalysis",
      "ESR",
      "Stool Analysis",
      "TSH",
      "T3",
      "T4",
      "Calcium",
      "Phosphorus",
      "HbsAg",
      "HIV 1 and 2 Screening",
      "Uric Acid",
      "BMI",
    ],
    packageCategory: "General Wellness",
    testDescription: "",
    packageName: "Women Wellness Test: Basic",
    price: 61450,
  },
  {
    id: 36,
    selected: true,
    listOfTest: [
      "Full Blood count",
      "Electrolytes",
      "Bicarbonate",
      "Urea",
      "Creatinine",
      "eGFR",
      "SGPT (ALT)",
      "Alkaline Phosphatase (ALP)",
      "SGOT (AST)",
      "Total Bilirubin",
      "Albumin",
      "Blood sugar",
      "Total Cholesterol ",
      "HDL",
      "LDL",
      "VLDL",
      "Triglycerides",
      "Urinalysis",
      "ESR",
      "Stool analysis",
      "TSH",
      "T3",
      "T4",
      "Calcium",
      "Phosphorus",
      "HbsAg",
      "HIV 1 & 2 screening",
      "Uric Acid",
      "BMI",
      "Hepatitis C screening",
      "Pap Smear",
      "HbA1C",
      "Vitamin B12",
    ],
    packageCategory: "General Wellness",
    testDescription: "",
    packageName: "Women Wellness Check: Gold",
    price: 91810,
  },

  {
    id: 38,
    selected: true,
    listOfTest: [
      "Full Blood count",
      "Electrolytes",
      "Biocarbonate",
      "Urea",
      "Creatine",
      "eGFR",
      "SGPT (ALT)",
      "Alkaline Phosphate (ALP)",
      "SGOT (AST)",
      "Total Bilirubin",
      "Albumin",
      "Blood Sugar",
      "Total Cholesterol",
      "HDL",
      "LDL",
      "VLDL",
      "Triglycerides",
      "urinalysis",
      "ESR",
      "Stool Analysis",
      "TSH",
      "T3",
      "T4",
      "Calcium",
      "Phosphorus",
      "HbsAG",
      "HIV 1 & 2 Screening",
      "Uric Acid",
      "BMI",
      "Hepatitis C Screening",
      "Pap Smear",
      "Hba1c",
      "Vitamin b12",
      "CEA",
      "HPV DNA Genotype",
    ],
    packageCategory: "General Wellness",
    testDescription: "",
    packageName: "Women Wellness Check: Platinum",
    price: 162210,
  },
  {
    id: 39,
    selected: true,
    listOfTest: [
      "Thyroid Stimulating Hormone (TSH)",
      "Thyroxine (T3)",
      "Tri-iodothyronine (T4)",
    ],
    packageCategory: "General Wellness",
    testDescription:
      "Balance your thyroid health with crucial tests, including thyroid-stimulating hormone (TSH), free thyroxine (FT4), free triiodothyronine (FT3), thyroglobulin, and thyroid peroxidase antibodies. ",
    packageName: "Thyroid Health",
    price: 14185,
  },

  {
    id: 40,
    selected: true,
    listOfTest: [
      "Creatinine",
      "Estimated Glomerular Filtration Rate (eGFR)",
      "Microalbumin",
      "Calcium",
      "Chloride",
      "Magnesium",
      "Potassium",
      "Sodium",
      "Urea",
      "Uric Acid",
      "Urine protein creatine ratio test",
      "Phencyclidine Phosphate",
    ],
    packageCategory: "General Wellness",
    testDescription:
      "Comprehensive blood tests to assess kidney health to maintain optimal kidney function. Vital tests such as creatinine, blood urea nitrogen (BUN), glomerular filtration rate (GFR), and electrolyte levels (sodium, potassium, and chloride).",
    packageName: "Kidney Health",
    price: 34635,
  },
  {
    id: 41,
    selected: true,
    listOfTest: [
      "Total Cholesterol",
      "LDL Cholesterol",
      "HDL Cholesterol",
      "Triglycerides",
      "Apolipoprotein A-I",
      "Lipoprotein (a)",
      "Apolipoproteins A1/B - [Serum]",
    ],
    packageCategory: "General Wellness",
    testDescription:
      "Monitor your heart health with essential tests, including cholesterol levels (LDL, HDL), triglycerides, blood pressure, fasting glucose, and C-reactive protein. These tests are indicators to determine whether the heart functions properly.",
    packageName: "Heart Health",
    price: 35758.14,
  },
  {
    id: 42,
    selected: true,
    listOfTest: [
      "Testosterone",
      "Oestradiol",
      "Follicle Stimulating Hormone",
      "Luteinising Hormone",
      "Prolactin",
    ],
    packageCategory: "Men's Health",
    testDescription:
      "Series of tests to determine male hormonal balance. A comprehensive test including testosterone, follicle-stimulating hormone (FSH), luteinizing hormone (LH), estradiol, thyroid-stimulating hormone (TSH), and prolactin.",
    packageName: "Male Hormonal",
    price: 33437,
  },
  {
    id: 43,
    selected: true,
    listOfTest: ["Glucose", "HbA1c", "Insulin", "C-peptide"],
    packageCategory: "General Wellness",
    testDescription:
      "This blood test helps to assess the cardiovascular indicators for a healthy heart. Key tests include Lipid profile (cholesterol, LDL, HDL), blood pressure monitoring, cardiac stress tests, and C-reactive protein.",
    packageName: "Cardiovascular Test",
    price: 48796,
  },
  {
    id: 44,
    selected: true,
    listOfTest: ["Blood Group", "Genotype"],
    packageCategory: "General Wellness",
    testDescription:
      "Understand your genetic makeup with blood group and genotype tests, providing insights into both your blood type and genetic traits. ",
    packageName: "Blood Group And Genotype",
    price: 5621.22,
  },
  {
    id: 45,
    selected: true,
    listOfTest: ["Glucose", "HBA1C", "Insulin", "C-Peptide"],
    packageCategory: "General Wellness",
    testDescription:
      "Understand your genetic makeup with blood group and genotype tests, providing insights into both your blood type and genetic traits. ",
    packageName: "Diabetes Health ",
    price: 42452.4,
  },
  //sexual Health
  // {
  //   id: 43,
  //   selected: true,
  //   listOfTest: [
  //     "Chlamydia",
  //     "Gonorrhoea",
  //     "Mycoplasma genitaliu",
  //     "Mycoplasma hominis",
  //     "Trichomoniasis",
  //     "Ureaplasma parvum",
  //     "Urea urealyticum",
  //   ],
  //   packageCategory: "Sexual Health",
  //   testDescription:
  //     "Promote sexual health with a comprehensive STI panel, covering tests for HIV 1 & 2, hepatitis B & C, Chlamydia",
  //   packageName: "STI 7 Panel",
  //   price: 107100,
  // },
  // {
  //   id: 43,
  //   selected: true,
  //   listOfTest: ["Chlamydia", "Gonorrhoea"],
  //   packageCategory: "Sexual Health",
  //   testDescription:
  //     "Promote sexual health with a comprehensive STI panel, covering tests for HIV 1 & 2, hepatitis B & C, Chlamydia",
  //   packageName: "STI 2 Panel",
  //   price: 78540,
  // },

  // Special Genetics Tests; DNA&BRCA
  {
    id: 46,
    selected: true,
    listOfTest: [],
    packageCategory: "Specialized Genetic Tests",
    testDescription:
      "DNA Paternity Test Peace of Mind is used to determine the biological relationship between a child and an alleged father. It involves analyzing DNA samples from both the child and the potential father to confirm or exclude paternity with a high degree of accuracy.",
    packageName:
      "DNA Paternity Test POM (Duo Test) - 25 Loci: Child and Alleged Father",
    price: 260100,
  },
  {
    id: 47,
    selected: true,
    listOfTest: [],
    packageCategory: "Specialized Genetic Tests",
    testDescription:
      "This is a trio paternity test. This test analyzes the DNA of all three individuals to determine the biological relationship between the child and the alleged parents. Including the mother helps in refining the analysis and providing more accurate results.",
    packageName:
      "DNA Paternity Test POM (Trio Test)- 25 Loci: Child, Mother  And Alleged Father",
    price: 280500,
  },
  {
    id: 48,
    selected: true,
    listOfTest: [],
    packageCategory: "Specialized Genetic Tests",
    testDescription: "",
    packageName: "DNA Paternity Test POM (additional person) -25 Loci ",
    price: 122400,
  },
  {
    id: 49,
    selected: true,
    listOfTest: [],
    packageCategory: "Specialized Genetic Tests",
    testDescription:
      "This often includes the collection of DNA samples by a neutral third party, strict chain of custody procedures, and verified identification of the Child and Alleged Father.",
    packageName:
      "DNA Paternity Test Legal (Duo Test) - 25 Loci: Child and Alleged Father",
    price: 386580,
  },
  {
    id: 50,
    selected: true,
    listOfTest: [],
    packageCategory: "Specialized Genetic Tests",
    testDescription:
      "This often includes the collection of DNA samples by a neutral third party, strict chain of custody procedures, and verified identification of the Child, Alleged Father and Mother",
    packageName:
      "DNA Paternity Test Legal (Trio Test)- 25 Loci: Child, Mother  And Alleged Father",
    price: 406980,
  },
  {
    id: 51,
    selected: true,
    listOfTest: [],
    packageCategory: "Specialized Genetic Tests",
    testDescription: "",
    packageName: "DNA Paternity Test POM (additional person) -25 Loci ",
    price: 132600,
  },

  // Paternity test
  {
    id: 52,
    selected: true,
    listOfTest: [
      "Trisomy 13",
      "Trisomy 18",
      "Trisomy 21",
      "Sex Chromosome Aneuploidies",
      "Fetus Sex Determination",
    ],
    packageCategory: "Specialized Genetic Tests",
    testDescription:
      "NIPTune C stands for Non-Invasive Prenatal Testing for Chromosomal Disorders. This test is designed to screen for chromosomal abnormalities in the fetus, including conditions like Down syndrome. It analyzes cell-free DNA from the mother's blood to assess the risk of specific chromosomal disorders during pregnancy.",
    packageName: "Prenatal Test: NIPT ",
    price: 477972.0,
    tag: "exoseq",
  },

  {
    id: 53,
    selected: true,
    listOfTest: [
      "Trisomy 13",
      "Trisomy 18",
      "Trisomy 21",
      "Sex Chromosome Aneuploidies",
      "Autosomal Aneuploidies in all other Chromosomes",
      "Fetus Sex Determination",
    ],
    packageCategory: "Specialized Genetic Tests",
    testDescription:
      "NIPTune S refers to the Non-Invasive Prenatal Testing for single-Gene Disorders. This test focuses on detecting specific genetic disorders or mutations in the fetus using cell-free DNA from the mother's blood. ",
    packageName: "●	Prenatal Test Plus: NIPT ",
    price: 513315.061,
    tag: "exoseq",
  },

  {
    id: 54,
    selected: true,
    listOfTest: [
      "chromosomal Abnormalities in Aneuploidy (extra or missing Chromosomes)",
    ],
    packageCategory: "Specialized Genetic Tests",
    testDescription:
      "PGTune A is a preimplantation genetic screening test that will identify chromosomal abnormalities in the embryos prior to transfer into the Uterus.",
    packageName: "Preimplantation Genetic Test",
    price: 597405.0,
  },

  {
    id: 55,
    selected: false,
    listOfTest: [],
    packageCategory: "Specialized Genetic Tests",
    testDescription: "Exome Sequencing -  Autism - Covering 252 genes",
    packageName: "Autism Genetic Diagnosis",
    price: 814572.0,
    tag: "exoseq",
  },
  {
    id: 56,
    selected: false,
    listOfTest: [],
    packageCategory: "Specialized Genetic Tests",
    testDescription:
      "Exome Sequencing- Male Infertility &Comprehensive Inflammatory and Auto inflammatory panel- Covering 432 genes",
    packageName: "Fertility Test: Genetic Diagnosis Test Male",
    price: 949212.061,
    tag: "exoseq",
  },
  {
    id: 57,
    selected: false,
    listOfTest: [],
    packageCategory: "Specialized Genetic Tests",
    testDescription:
      "Exome Sequencing- Female Infertility & Comprehensive Inflammatory and Auto inflammatory panel Covering 400 genes",
    packageName: "Fertility Test: Genetic Diagnosis Test  Female",
    price: 949212.061,
    tag: "exoseq",
  },

  // EatWell packages
  {
    id: 58,
    selected: true,
    listOfTest: [
      "Energy & Weight Management",
      "Heart Health",
      "Eating Behaviours",
    ],
    packageCategory: "Specialized Genetic Tests",
    testDescription:
      "A 3 panel test consists of Energy & weight management, Heart health and Eating behaviours.",
    packageName: "Consumer Genetics: Eatwellgx",
    price: 428819.995,
  },
  {
    id: 59,
    selected: true,
    listOfTest: [
      "Vitamin Requirements",
      "Mineral Requirements",
      "Detoxification Ability",
      "Food Intolerance",
    ],
    packageCategory: "Specialized Genetic Tests",
    testDescription:
      "A 4 panel test consists of: Vitamin requirements, mineral requirements, detoxification ability and food intolerance",
    packageName: "Consumer Genetics:Eatwellgx plus",
    price: 454305.001,
  },
  {
    id: 60,
    selected: true,
    listOfTest: ["Muscle Profile", "Injury & Recovery", "Recovery Nutrition."],
    packageCategory: "Specialized Genetic Tests",
    testDescription:
      "Consists of 3 panels, namely: muscle profile, injury & recovery, recovery nutrition.",
    packageName: "Consumer Genetics: Fitwellgx",
    price: 428819.995,
  },
  {
    id: 61,
    selected: true,
    listOfTest: [
      "Response to Exercise",
      "Recovery from Exercise & Injury Risk",
      "Body Weight Management",
      "Diet Responses",
    ],
    packageCategory: "Specialized Genetic Tests",
    testDescription:
      "Consists of 4 panels, namely: Response to exercise, recovery from exercise & injury risk, body weight management, diet responses.",
    packageName: "Consumer Test: Fitwellgx plus",
    price: 454305.001,
  },
  {
    id: 62,
    selected: true,
    listOfTest: [
      "White Blood Cells",
      "Infection & Inflammation Resistance",
      "Histamine Intolerance",
      "Micronutrients & Immunity",
    ],
    packageCategory: "Specialized Genetic Tests",
    testDescription:
      "Consists of 4 panels, namely: white blood cells, infection & inflammation resistance, histamine intolerance, micronutrients & immunity",
    packageName: "Consumer Test: Immuwellgx",
    price: 428819.995,
  },
  {
    id: 63,
    selected: true,
    listOfTest: [
      "ACE2 Receptor Genes",
      "ACE2 Enzyme",
      "Severe SARS Viral and Covid19 Infections,",
      "Inflammatory Markers",
      "Blood Oxygenation",
      "Trace Element Levels",
    ],
    packageCategory: "Specialized Genetic Tests",
    testDescription:
      "Consists of 6 panels, namely: ACE2 receptor genes, ACE2 enzyme, Severe SARS viral and Covid19 infections, inflammatory markers, blood oxygenation, trace element levels",
    packageName: "Consumer: Immuwellgx plus",
    price: 454305.001,
  },
];
