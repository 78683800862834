import React from "react";
import styled from "styled-components";
import tick from "../tick.png";

interface Show {
  show: boolean;
}
const Container = styled.div`
  width: 100%;
  position: absolute;
  height: 100;
  top: 0;
  z-index: 100;
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
const Modal = styled.div<Show>`
  background-color: white;
  border-radius: 24px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: absolute;
  left: 50%;
  padding: 0 30px 30px;
  top: ${(props) => (props.show ? "50%" : "0")};
  transform: ${(props) =>
    props.show
      ? "translate(-50%, -50%) scale(1)"
      : "translate(-50%, -50%) scale(0.1)"};
  display: flex;
  flex-direction: column;
  z-index: 1;
  width: 400px;
  text-align: center;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  transition: transform 0.4s;
  transition-delay: 0.1s;
`;
const Heading = styled.h2`
  font-size: 38px;
  font-weight: 500;
  margin: 30px 0 10px;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
const Paragraph = styled.p`
  font-size: 14px;
`;
const ButtonContainer = styled.div`
  display: flex;
`;
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Image = styled.img`
  width: 100px;
  margin-top: -50px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
`;
const Button = styled.button`
  width: 100%;
  margin-top: 50px;
  padding: 10px 0;
  background: #62c33e;
  color: #fff;
  border: 0;
  outline: none;
  font-size: 18px;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
`;

interface AppProps {
  show: boolean;
  setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}
const SuccessModal = ({ show, setShowSuccess }: AppProps) => {
  return (
    <Container>
      <Modal show={show}>
        <ImageContainer>
          <Image src={tick} alt="success Logo" />
        </ImageContainer>
        <Heading>Order Completed ! </Heading>

        <Paragraph>
          Please walk into either of our centers in Lekki or Anthony to take
          your tests
        </Paragraph>

        <ButtonContainer>
          <Button
            onClick={() => {
              setShowSuccess(false);
            }}
          >
            Close
          </Button>
        </ButtonContainer>
      </Modal>
    </Container>
  );
};

export default SuccessModal;
