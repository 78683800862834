import React from "react";
import logo from "../logoBio.png";
const Footer = () => {
  return (
    <footer
      className="text-center text-lg-start text-dark"
      style={{ backgroundColor: "#BAC8F4", height: "100%", margin: 0 }}
    >
      <section style={{ paddingTop: "1px" }}>
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <img src={logo} alt="Biologix-Logo" height={50} />

              <p></p>
            </div>

            <div
              className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4"
              style={{ marginBottom: 0 }}
            >
              <h6 className="text-uppercase fw-bold">Quick Links</h6>
              <hr
                className="mb-2 mt-0 d-inline-block mx-auto"
                style={{
                  width: "60px",
                  backgroundColor: "#7c4dff",
                  height: "2px",
                  marginBottom: 0,
                }}
              />
              <p style={{ margin: "5px 0" }}>
                <a
                  href="https://www.lab.biologix.com.ng/"
                  className="text-dark"
                  style={{ textDecoration: "none", padding: 0 }}
                >
                  Home
                </a>
              </p>
              <p style={{ margin: "5px 0 " }}>
                <a
                  href="https://www.lab.biologix.com.ng/contact-us/"
                  className="text-dark"
                  style={{ textDecoration: "none" }}
                >
                  Contact Us
                </a>
              </p>
              <p style={{ margin: "5px 0 " }}>
                <a
                  href="https://www.lab.biologix.com.ng/faqs/"
                  className="text-dark"
                  style={{ textDecoration: "none" }}
                >
                  FAQs
                </a>
              </p>
            </div>

            <div
              className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4"
              style={{ marginBottom: 0 }}
            >
              <h6 className="text-uppercase fw-bold">Our Services</h6>
              <hr
                className="mb-2 mt-0 d-inline-block mx-auto"
                style={{
                  width: "60px",
                  backgroundColor: "#7c4dff",
                  height: "2px",
                }}
              />
              <p style={{ margin: "5px 0 " }}>
                <a
                  href="https://www.lab.biologix.com.ng/test-categories/"
                  className="text-dark"
                  style={{ textDecoration: "none" }}
                >
                  Book a Test
                </a>
              </p>
              <p style={{ margin: "5px 0 " }}>
                <a
                  href="https://www.lab.biologix.com.ng/book-a-test/"
                  className="text-dark"
                  style={{ textDecoration: "none" }}
                >
                  Book an Appointment
                </a>
              </p>
            </div>

            <div
              className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-0"
              style={{ marginBottom: 0 }}
            >
              <h6 className="text-uppercase fw-bold">Useful Links</h6>
              <hr
                className="mb-2 mt-0 d-inline-block mx-auto"
                style={{
                  width: "60px",
                  backgroundColor: "#7c4dff",
                  height: "2px",
                }}
              />
              <p style={{ margin: "5px 0 " }}>
                <a
                  href="https://www.ncdc.gov.ng/"
                  className="text-dark"
                  style={{ textDecoration: "none" }}
                >
                  NCDC
                </a>
              </p>
              <p style={{ margin: "5px 0 " }}>
                <a
                  href="https://hefamaa.lagosstate.gov.ng/"
                  className="text-dark"
                  style={{ textDecoration: "none" }}
                >
                  HEFAMAA
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>

      <div
        className="text-center p-3"
        style={{ backgroundColor: "#001968", color: "white" }}
      >
        © 2023 Biologix Laboratories. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
