import styled from "styled-components";
import ReactPaginate from "react-paginate";

const MyPaginate = styled(ReactPaginate).attrs({
  // You can redefine classes here, if you want.
  activeClassName: "active", // default to "selected"
})`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;

  list-style-type: none;
  padding: 0 5rem;

  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: 1px solid #ececec;
    cursor: pointer;
    text-decoration: none;
    margin-left: 1rem;
    font-weight: 400;
    color: #003766;
  }
  li a:hover {
    background-color: #ed4e4e;
    color: white;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.active a {
    background-color: #ed4e4e;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;
export default MyPaginate;
